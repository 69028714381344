<template>
    <div>
        <NavBreadcrumb></NavBreadcrumb>
        <div class="panel-style device">
            <el-form :inline="true" :model="searchForm" size="mini">
                <el-form-item label="归属机构" prop="officeId">
                    <el-select v-model="searchForm.orgId" placeholder="请选择用户机构" clearable>
                        <el-option v-for="item in orgOption" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="基站名称" prop="stationName">
                    <el-input v-model="searchForm.stationName" placeholder="基站名称"/>
                </el-form-item>
                <el-form-item label="归属地区" prop="areaName">
                    <el-cascader
                        :options="areaOptions"
                        :props="areaProps"
                        @change="searchAreaChange"
                        clearable>
                    </el-cascader>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="queryDeviceInfo">查询</el-button>
                    <el-button type="primary" @click="openAddDevicePage">新增</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="tableData" border size="small" stripe style="width: 100%"
                      :header-cell-style="{background:'#eef1f6',color:'#606266'}" v-loading="listLoading">
                <el-table-column prop="deviceNum" label="设备编号" align="center" width="120"/>
                <el-table-column prop="orgName" label="归属机构" />
                <el-table-column prop="stationName" label="归属基站" />
                <el-table-column prop="areaName" label="所属地区"/>
                <el-table-column prop="ipcVersion" label="版本号" align="center"  width="90" :formatter="ipcVersionFormatter" />
                <el-table-column prop="online" label="是否在线" align="center" width="80">
                    <template slot-scope="scope">
                        <el-tooltip v-if="scope.row.online === '离线'" class="item" effect="dark" content="离线" placement="right">
                            <div class="online-style" style="background: #b3abab;"></div>
                        </el-tooltip>
                        <el-tooltip v-if="scope.row.online === '在线'" class="item" effect="dark" content="在线" placement="right">
                            <div class="online-style" style="background: #67C23A;"></div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="运维状态" align="center" width="90">
                    <template slot-scope="scope">
                        <el-tooltip v-if="scope.row.status === '1'" class="item" effect="dark" content="运维中" placement="right">
                            <i class="el-icon-s-tools" style='color: rgba(243,112,5,0.76);'></i>
                        </el-tooltip>
                        <el-tooltip v-else class="item" effect="dark" content="正常" placement="right">
                            <i class="el-icon-success" style='color: #67C23A;'></i>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" fixed="right" width="280">
                    <template slot-scope="scope">
                        <div class="option-btn-div">
                            <el-tooltip v-if="showDeviceDataButton(scope.row.deviceNum)"
                                        v-delTabIndex effect="dark" content="设备数据" placement="top">
                                <i class="doart-icon-info option-btn-normal" @click="handleDeviceDataClickEvent(scope.row.deviceNum)"/>
                            </el-tooltip>
                            <el-tooltip v-else v-delTabIndex effect="dark" content="设备数据" placement="top">
                                <i class="doart-icon-info option-btn-normal" @click="openDeviceManagePage(scope.row.id, scope.row.deviceNum)"/>
                            </el-tooltip>
                            <el-tooltip v-delTabIndex effect="dark" content="事件" placement="top">
                                <i class="doart-icon-event option-btn-normal" @click="openEventsRecordPage(scope.row.id)"/>
                            </el-tooltip>
                            <el-tooltip v-delTabIndex effect="dark" content="操作记录" placement="top">
                                <i class="el-icon-document option-btn-normal" @click="openOptionRecordPage(scope.row.id, scope.row.deviceNum)"/>
                            </el-tooltip>
                            <el-tooltip v-delTabIndex effect="dark" content="指令控制" placement="top">
                                <i class="el-icon-setting option-btn-normal" @click="openCmdControlPage(scope.row.id, scope.row.deviceNum)"/>
                            </el-tooltip>
                            <el-tooltip effect="dark" content="编辑" placement="top">
                                <i class="el-icon-edit option-btn-normal" @click="openEditDevicePage(scope.row.id)"/>
                            </el-tooltip>
                            <el-tooltip v-delTabIndex effect="dark" content="删除" placement="top">
                                <i class="el-icon-delete option-btn-normal" style="color: #F56C6C"
                                   @click="handleDelete(scope.row.id, scope.row.deviceNum)"/>
                            </el-tooltip>
                            <el-tooltip v-delTabIndex effect="dark" content="数据导出" placement="top">
                                <i class="doart-icon-export option-btn-normal" @click="openDeviceDataExportPage(scope.row.id, scope.row.deviceNum)"/>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div style="margin-top: 15px;text-align: right;">
                <el-pagination background layout="total, prev, pager, next, jumper"
                    @current-change="handleCurrentChange" :total="tableTotal" :current-page.sync="curr"/>
            </div>
            <!-- 设备数据 -->
            <DeviceDataDetail ref="deviceDataDetailPage" @getTableData="getTableData"></DeviceDataDetail>
            <!--设备信息-->
            <el-dialog id="deviceInfoIndexDialog" :title="deviceInfoTitleName" custom-class="device-info-dialog-class"
                    class="deviceManage" center :visible="deviceInfoPageVisible" top="0"
                    @close="deviceInfoPageVisible = false" :close-on-click-modal="false" :destroy-on-close = true :close-on-press-escape="false">
                <DeviceInfoIndex ref="deviceInfoIndexPage"></DeviceInfoIndex>
            </el-dialog>
            <!--事件-->
            <el-dialog title="事件记录" :visible="eventsRecordPageVisible" width="80%" top="0" center
                   @close="eventsRecordPageVisible = false" :close-on-click-modal="false" :destroy-on-close=true>
                <EventsRecord :pageType="'2'" ref="eventsPage"></EventsRecord>
            </el-dialog>
            <!--操作记录-->
            <el-dialog :title="optionRecordTitleName" custom-class="op-dialog-class" center :visible="optionRecordPageVisible" top="0"
                   @close="optionRecordPageVisible = false" :close-on-click-modal="false" :destroy-on-close = true>
                <DeviceOptionRecord ref="opRecordPage"></DeviceOptionRecord>
            </el-dialog>
            <!--指令控制-->
            <el-dialog id="deviceCmdIndexDialog" :title="deviceCmdTitleName" top="0" custom-class="device-cmd-dialog-class" center :visible.sync="deviceCmdIndexPageVisible"
                   @close="deviceCmdIndexPageVisible = false" :close-on-click-modal="false" :destroy-on-close = true :close-on-press-escape="false">
                <DeviceCmdIndex ref="deviceCmdIndexPage"></DeviceCmdIndex>
            </el-dialog>
            <!--编辑设备-->
            <DeviceParstInfo ref="devicePartsInfoPage" v-on:queryDeviceInfo="queryDeviceInfo"></DeviceParstInfo>
            <!--数据导出功能页面-->
            <el-dialog :title="deviceDataExportTitleName" custom-class="export-data-dialog-class" center :visible="deviceDataExportPageVisible"
                       @close="deviceDataExportPageVisible = false" top="0" :close-on-click-modal="false" :destroy-on-close = true>
                <DeviceDataExport ref="deviceDataExportPage"></DeviceDataExport>
            </el-dialog>
            <!--告警记录-->
            <el-dialog title="告警信息" custom-class="warn-dialog" center :visible="warnInfoPageVisible" top="0"
                       @close="warnInfoPageVisible = false" :close-on-click-modal="false" :destroy-on-close = true>
                <DeviceWarnRecord ref="warnInfoPage"></DeviceWarnRecord>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import {
        delDeviceInfoById,
        exportPartInfos,
        queryAreaInfo,
        queryDeviceInfo,
        queryOrgInfo,
        queryStationInfoByParam
    } from "@/api/station/deviceInfoApi";
    import NavBreadcrumb from "../../../components/NavBreadcrumb"
    import DeviceParstInfo from "./device-parts-Info.vue";
    import DeviceWarnRecord from "./device-warn-info.vue";
    import EventsRecord from "../EventsRecord";
    import DeviceInfoIndex from "@/views/station/device/device-info-index.vue";
    import DeviceCmdIndex from "./device-cmd-index.vue";
    import DeviceOptionRecord from "./device-option-record.vue";
    import DeviceDataExport from "./device-data-export.vue";
    import DeviceDataDetail from '@/views/station/device/device-data-detail.vue';

    export default {
        name: "DeviceInfoManage",
        provide(){
            return{
                openOptionRecordPage:this.openOptionRecordPage,
                openWarnInfoPage:this.openWarnRecordPage,
                openEventsRecordPage:this.openEventsRecordPage
            }
        },
        components: {
            NavBreadcrumb,
            DeviceParstInfo,
            DeviceWarnRecord,
            EventsRecord,
            DeviceInfoIndex,
            DeviceCmdIndex,
            DeviceOptionRecord,
            DeviceDataExport,
            DeviceDataDetail
            },
        data() {
            return {
                exportLoading: false,
                listLoading: false,
                orgOption: [],
                searchForm: {

                    orgId: '',
                    stationName: '',
                    areaName: '',
                    areaLevel: ''
                },
                tableData:[],
                tableTotal: 0,
                curr: 1,
                size: 10,
                areaOptions: [],
                areaProps: {

                    value: 'areaKey',
                    label: 'areaName',
                    children: 'subAreas',
                    checkStrictly: true
                },
                addOrEditDeviceFormVisible:false,
                warnInfoPageVisible: false,
                deviceInfoPageVisible: false,
                deviceCmdIndexPageVisible:false,
                eventsRecordPageVisible:false,
                optionRecordPageVisible: false,
                deviceDataExportPageVisible:false,
                currWholeId: '',
                currDeviceNum: '',
                deviceInfoTitleName: '',
                deviceCmdTitleName: '',
                optionRecordTitleName: '',
                deviceDataExportTitleName: '',
            };
        },
        methods : {

            ipcVersionFormatter(row, column, cellValue, index) {
                if (row.ipcVersion != null && row.ipcVersion != '') {

                    if ((row.deviceNum === '080121010002' || row.deviceNum === '080121010003')) {

                        if (row.ipcVersion.length > 2) {

                            return row.ipcVersion.split(["."])[2] + '.' + row.ipcVersion.split(["."])[1] + '.' + row.ipcVersion.split(["."])[0];
                        } else {

                            return '--';
                        }
                    } else {

                        return row.ipcVersion;
                    }
                } else {

                    return '--';
                }
            },
            // 查询设备信息
            queryDeviceInfo() {

                this.getTableData();
            },
            // 查询地区信息
            queryAreaInfo() {

                queryAreaInfo().then((res) => {

                    this.areaOptions = res.data;
                }).catch(err => console.error(err));
            },
            // 查询条件的区域变化更新areaLevel、areaName.
            searchAreaChange(value) {

                if (value && value.length > 0) {

                    this.searchForm.areaLevel = value.length;
                    this.searchForm.areaName = value[value.length - 1];
                } else {

                    this.searchForm.areaLevel = '';
                    this.searchForm.areaName = '';
                }
            },
            // 查询列表信息
            getTableData() {

                let params = {

                    curr: this.curr,
                    size: this.size,
                    orgId: this.searchForm.orgId,
                    stationName: this.searchForm.stationName,
                    areaName: this.searchForm.areaName,
                    areaLevel: this.searchForm.areaLevel
                };
                this.listLoading = true;
                queryDeviceInfo(params).then((res) => {

                    this.tableTotal = res.data.totalNum;
                    this.tableData = res.data.listResult;
                    this.listLoading = false;
                }).catch(err=> console.log(err));
            },
            // 处理整机设备分页
            handleCurrentChange(val) {

                this.curr = val;
                this.getTableData();
            },
            // 获取机构列表
            getOrgList(){

                let params = {

                    curr: 0,
                    size: 0
                };
                queryOrgInfo(params).then((res) => {

                    this.orgOption = res.data.list;
                }).catch(err => console.error(err));
            },
            // 机构改变触发
            orgChangeEdit() {

                let params = {

                    orgId : this.editForm.orgId,
                };
                queryStationInfoByParam(params).then((res) => {

                    this.stationOption = res.data;
                }).catch(err => console.error(err));
                this.$forceUpdate();
            },
            // 打开添加页面
            openAddDevicePage() {

                this.addOrEditDeviceFormVisible = true;
                this.$refs.devicePartsInfoPage.initAddOrEditPage('');
            },
            // 打开编辑页面
            openEditDevicePage(id) {

                this.addOrEditDeviceFormVisible = true;
                this.$refs.devicePartsInfoPage.initAddOrEditPage(id);
            },
            // 设备删除
            handleDelete(id, deviceNum) {

                this.$confirm('是否需要删除？', '提示', {cancelButtonClass: "btn-custom-cancel"}).then(() => {

                    let params = {

                        id: id,
                        number: deviceNum
                    };
                    delDeviceInfoById(params).then(() => {

                        this.getTableData();
                    }).catch(err => console.error(err));
                });
            },
            // 打开告警信息页面
            openWarnRecordPage(id) {

                this.warnInfoPageVisible = true;
                this.$nextTick(function(){

                    this.$refs.warnInfoPage.initWarnInfoPage(id);
                });
            },
            // 打开事件记录页面
            openEventsRecordPage(id) {

                this.eventsRecordPageVisible = true;
                this.$nextTick(function() {

                    this.$refs.eventsPage.initPageData(id);
                });
            },
            // 打开设备信息页面
            openDeviceManagePage(id, deviceNum) {

                this.deviceInfoPageVisible = true;
                this.deviceInfoTitleName = "设备信息" + "【" + deviceNum + "】";
                this.$nextTick(function() {

                    this.$refs.deviceInfoIndexPage.initPage(id, deviceNum);
                });
            },
            // 打开指令控制页面
            openCmdControlPage(id, deviceNum) {

                this.deviceCmdIndexPageVisible = true;
                this.deviceCmdTitleName = '指令控制' + "【" + deviceNum + "】";
                this.$nextTick(function() {

                    this.$refs.deviceCmdIndexPage.initPage(id, deviceNum);
                });
            },
            // 打开设备操作记录页面
            openOptionRecordPage(id, deviceNum) {

                this.optionRecordPageVisible = true;
                this.optionRecordTitleName = "操作记录" + "【" + deviceNum + "】";
                this.$nextTick(function() {

                    this.$refs.opRecordPage.queryOptionRecord(id);
                });
            },
            // 导出结构数据
            exportPartInfos(id) {

                let params = {

                    deviceWholeId: id
                };
                this.exportLoading = true;
                exportPartInfos(params).then(res => {

                    this.exportLoading = false;
                    const blob = new Blob([res]);
                    const fileName = "设备组件信息.xlsx";
                    //  创建 a标签
                    const eLink = document.createElement('a');
                    eLink.download = fileName;
                    // 创建下载链接
                    eLink.href = URL.createObjectURL(blob);
                    document.body.appendChild(eLink);
                    eLink.click();
                    // 释放掉blob对象
                    URL.revokeObjectURL(eLink.href);
                    // 下载完成移除元素
                    document.body.removeChild(eLink);
                })
            },
            // 打开数据导出页面
            openDeviceDataExportPage(id, deviceNum) {

                this.deviceDataExportPageVisible = true;
                this.deviceDataExportTitleName =  "数据导出" + "【" + deviceNum + "】";
                this.$nextTick(function(){

                    this.$refs.deviceDataExportPage.initPage(id, deviceNum);
                });
            },
            showDeviceDataButton(deviceSn) {

                // 肖屋丁和才纳基站没有新协议的数据 所以不展示该按钮
                return deviceSn !== '080121010002' && deviceSn !== '080121010003';
            },
            handleDeviceDataClickEvent(deviceSn) {

                this.$refs.deviceDataDetailPage.initDeviceDetailPage(deviceSn);
            }
        },
        mounted() {

            this.queryAreaInfo();
            this.getOrgList();
            this.queryDeviceInfo();
            this.$websocket.dispatch("setIsDebug", true);
            this.$websocket.dispatch("init", "/doartIot/es-manager/esManagerPoint");
        },
        beforeDestroy() {

            this.$websocket.dispatch('destroy');
        }
    }
</script>
<style>

</style>
<style scoped lang="scss">
    ::v-deep .deviceManage .el-scrollbar__wrap {

        overflow-x: hidden;
    }
    ::v-deep .el-dialog .el-dialog__body {

        flex:1;
        overflow: auto;
    }
    ::v-deep .device .el-scrollbar__bar.is-vertical {

        width: 10px;
        border-radius: 3px;
    }
    ::v-deep .device .device-info-dialog-class {

        width: 1325px;
    }
    ::v-deep .device .warn-dialog-class {

        width: 1200px;
        height: 750px;
    }
    ::v-deep .device .op-dialog-class {

        width: 1200px;
        height: 660px;
    }
    ::v-deep .device .export-data-dialog-class {

        width: 650px;
        height: 280px;
    }
    ::v-deep .device .device-cmd-dialog-class {

        width: 1300px;
        height: 700px;
    }
    ::v-deep .device .device-module-dialog {

        width: 600px;
    }
    ::v-deep .device .myField {

        border: 1px solid #ccc;
    }
    ::v-deep .device .dialog-aside {

        border: 1px #357cc5 solid;
        height: 720px;
        margin-right: 4px;
    }
    ::v-deep .device .dialog-main {

        border: 1px #357cc5 solid
    }
    ::v-deep .device .detailButton {

        border: 0;
        background-color: #ECF0F5;
        color: #409EFF;
        padding: 15px 0;
        font-weight: 900;
        position: absolute;
        bottom: 0;
        right: 15px;
    }
    ::v-deep .deviceManage .el-form-item__label {

        padding: initial;
    }
    .online-style {

        width: 10px;
        height: 10px;
        background: #00D30F;
        border-radius: 50%;
        margin-left: 45%;
        cursor: pointer;
    }
    .option-btn-normal {

        font-size: 18px;
        color: #409EFF;
        cursor: pointer;
    }
    .icon-item {

        height: 18px;
        width: 18px;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .doart-icon-info {

        @extend .icon-item;
        background-image: url('../../../assets/icon/button/info.svg');
    }
    .doart-icon-event {

        @extend .icon-item;
        background-image: url('../../../assets/icon/button/event.svg');
    }
    .doart-icon-export {

        @extend .icon-item;
        background-image: url('../../../assets/icon/button/export.svg');
    }
    .option-btn-div {

        display: flex;
        justify-content: space-around;
    }
</style>
