<template>
    <div>
        <el-form :inline="false" :model="dataForm" :rules="rules" ref="dataForm" size="mini" label-width="80px">
            <el-form-item label="上报时间" prop="dateTime">
                <el-date-picker
                    value-format="yyyy-MM-dd" v-model="dataForm.dateTime" type="daterange"
                    start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="数据类型" prop="types">
                <el-checkbox-group v-if="stationNewType" v-model="dataForm.types">
                    <el-checkbox v-for="item in commandInfoList"
                         :key="item.commandCode" :label="item.commandCode" name="type">
                        {{item.commandName}}
                    </el-checkbox>
                </el-checkbox-group>
                <el-checkbox-group v-else v-model="dataForm.types">
                    <el-checkbox label="1" name="type">发动机</el-checkbox>
                    <el-checkbox label="2" name="type">电池组</el-checkbox>
                    <el-checkbox label="3" name="type">光伏模块</el-checkbox>
                    <el-checkbox label="4" name="type">整流模块</el-checkbox>
                    <el-checkbox label="5" name="type">直流模块</el-checkbox>
                </el-checkbox-group>
            </el-form-item>
        </el-form>
        <div class="download-footer">
            <el-button type="primary" :loading="exportLoading" size="mini" @click="downloadData">下载</el-button>
        </div>
    </div>
</template>
<script>
    import {exportDeviceData} from '@/api/station/deviceInfoApi';
    import { queryDeviceDataCommandList, exportEsDeviceData } from '@/api/es/device/esDeviceInfo';
    import Global from '@/components/Global';
    export default {

        name: "deviceDataExport",
        data() {

            return {

                dataForm:{

                    dateTime: '',
                    // 1：发动机数据 2：电池组数据 3：光伏模块 4：整流模块
                    types:[]
                },
                rules: {
                    dateTime: [
                        { required: true, message: '请选择开始时间与结束时间', trigger: ['blur', 'change'] }
                    ],
                    types: [
                        { required: true, message: '请至少选择一个类型', trigger: ['blur', 'change'] }
                    ]
                },
                exportLoading:false,
                pickerOptions: {

                    onPick({maxDate, minDate}) {

                        if (minDate && !maxDate) {

                            self.firstSelectDate = minDate.getTime();
                        }
                        if (maxDate) {

                            self.firstSelectDate = '';
                        }
                    },
                    // 只允许查询7天范围的数据
                    disabledDate(time) {

                        if (self.firstSelectDate) {

                            let timeRange = 6 * 24 * 60 * 60 * 1000;
                            return time.getTime() > self.firstSelectDate + timeRange ||
                                time.getTime() < self.firstSelectDate - timeRange || time.getTime() > Date.now();
                        } else {

                            return time.getTime() > Date.now();
                        }
                    }
                },
                deviceId:'',
                orgName:'',
                stationName:'',
                deviceSn: '',
                commandInfoList: '',
                // 区分特指肖屋丁和才纳基站和后面有新增协议数据的电站 false：特指肖屋丁和才纳基站 没有新协议的数据 true：有新增的协议数据
                stationNewType: false
            };
        },
        methods: {
            /**
             * 初始化页面
             * @param deviceId 设备id
             * @param deviceSn 设备编号
             */
            initPage(deviceId, deviceSn) {

                this.deviceId = deviceId;
                this.deviceSn = deviceSn;
                this.checkStationType();
            },
            /**
             * 关闭页面.
             */
            closePage() {

                this.$parent.closeOpRecordPage();
            },
            /**
             * 下载设备数据.
             */
            downloadData() {

                if (this.stationNewType) {

                    this.exportData();
                } else {
                    // 肖屋丁、才纳基站 调用老方法
                    this.exportDataOld();
                }
            },
            /**
             * 导出数据方法.
             */
            exportData() {

                this.$refs['dataForm'].validate((valid) => {

                    if (valid) {
                        // 构建参数，开始导出
                        let params = {

                            deviceSn: this.deviceSn,
                            commandInfoList: this.dataForm.types,
                            startDate: this.dataForm.dateTime[0],
                            endDate: this.dataForm.dateTime[1]
                        };
                        this.exportLoading = true;
                        exportEsDeviceData(params).then(res => {

                            this.exportResDataHandle(res);
                        });
                    }
                });
            },
            /**
             * 导出数据方法(旧).
             */
            exportDataOld() {

                this.$refs['dataForm'].validate((valid) => {

                    if (valid) {
                        // 校验数据类型
                        let typesStr = '';
                        for (let i = 0; i < this.dataForm.types.length; i++) {

                            typesStr = typesStr + "" + this.dataForm.types[i];
                        }
                        // 构建参数，开始导出
                        let params = {

                            deviceWholeId: this.deviceId,
                            types: typesStr,
                            startTime: this.dataForm.dateTime[0] + ' 00:00:00',
                            endTime: this.dataForm.dateTime[1] + ' 23:59:59'
                        };
                        this.exportLoading = true;
                        exportDeviceData(params).then(res => {

                            this.exportResDataHandle(res);
                        });
                    }
                });
            },
            exportResDataHandle(res) {

                this.exportLoading = false;
                const blob = new Blob([res]);
                const fileName = "设备数据_" + this.deviceSn + ".xlsx";
                const elink = document.createElement('a');
                elink.download = fileName;
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href);
                document.body.removeChild(elink);
            },
            checkStationType() {
                // 肖屋丁和才纳基站没有新协议的数据 所以不展示该按钮
                this.stationNewType = this.deviceSn !== '080121010002' && this.deviceSn !== '080121010003';
                if (this.stationNewType) {

                    this.queryDeviceDataCommandList();
                }
            },
            /**
             * 查询设备的指令集合.
             */
            queryDeviceDataCommandList() {

                let param = {

                    sn: this.deviceSn,
                }
                queryDeviceDataCommandList(param).then(res => {

                    if (res.code === Global.response_status_success_obj) {

                        this.commandInfoList = res.data;
                        if (this.commandInfoList && this.commandInfoList.length > 0) {

                            console.log(this.commandInfoList)
                        }
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => {

                    message.error('请求失败,请稍后再试');
                    console.error(err);
                });
            },
        }
    }
</script>
<style>

</style>
<style scoped="scoped">
.download-footer {

    text-align: center;
}
</style>
